import React, { useState } from 'react';

export const FlashMessageContext = React.createContext({
  messageText: '',
  messageClass: '',
  showMessage: () => { }
});

export const FlashMessageContextProvider = props => {

  /*********************************************
   * INITIALIZATION
   *********************************************/
  const [messageText, setMessageText] = useState(null);

  // The CSS class for the message (default, warning, error, etc)
  const [messageClass, setMessageClass] = useState(null);


  /*********************************************
   * EVENT HANDLERS
   *********************************************/

  const showMessageHandler = (messageText, messageClass) => {
    // Dismiss the message after a given time
    setTimeout(() => {
      setMessageText(null);
      setMessageClass(null);
    }, 7000);
    setMessageText(messageText);
    setMessageClass(messageClass);
  };


  /*********************************************
   * CONTEXT PROVIDER
   *********************************************/

  return (
    <FlashMessageContext.Provider value={{
      messageText: messageText,
      messageClass: messageClass,
      showMessage: showMessageHandler
    }}>
      {props.children}
    </FlashMessageContext.Provider>
  )
};