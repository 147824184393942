import React, {useEffect, useReducer, useRef, useState, useContext} from 'react';
import {useLocation} from 'react-router-dom'
import {handshapes, locations, movements} from '../../lexeme/data/graphemes.json';

import {camelToSnake} from '../../../utils/stringUtils';

import PrimaryMenu from "./PrimaryMenu";
import IsMobileContext from '../../../context/isMobile-context';

import '../css/NavBar.css'
import ReactGA from "react-ga";


/**
 * This component attempts to abstract out non-rendering code from the grapheme search menus. 
 */
function GraphemeSearchBar() {

  /***********************
   * INITIALIZATION
   ***********************/

  const validQueryParams = ['primary_handshape_id', 'secondary_handshape_id', 'location_id', 'primary_movement_id', 'secondary_movement_id'];

  // We need this to be able to get clicks outside of the dropdown menu so
  // that we can hide it if the user clicks outside
  const menuContainerRef = useRef();

  ////////////////////
  // URI Services
  ////////////////////
  const locationService = useLocation();
  // Get the query params
  const query = new URLSearchParams(locationService.search);

  /**
   * Util function to update with new query params
   * @param {*} param 
   * @param {*} value 
   */
  function updateSearchParams(param, value) {
    // If we're using this component (the Grapheme Search), then we know we don't need the English query. And it would
    // cause problems if it was there.
    if (validQueryParams.includes(param)) {
      query.delete('english_tags');
      query.set(param, value);
      window.location.replace(`/search?${query.toString()}`);
    }
  }

  ////////////////////
  // Context objects
  ////////////////////
  const isMobileContext = useContext(IsMobileContext);
  // This seems crazy to have to do this, but for some reason, iOS needs an extra click than any other
  // platform. Hopefully they'll fix this and we can take all isMobile out of this component.
  const isIOS = isMobileContext.isIOS;

  ////////////////////
  // State objects
  ////////////////////
  const [focusedSearchCategory, setFocusedSearchCategory] = useState(null);
  const [focusedDropdownMenuElementId, setFocusedDropdownMenuElementId] = useState(null);
  const [focusedSubmenuElementId, setFocusedSubmenuElementId] = useState(null);
  // This is a toggle to enable the 2nd click functionality for mobile
  const [activateMobileTarget, setActivateMobileTarget] = useState(false);


  /*******************************
   * GRAPHEME SEARCH STATE REDUCER
   *******************************/

  const [graphemeSearch, graphemeSearchDispatch] = useReducer(graphemeSearchReducer, initializeSearchGraphemes());

  /**
   * Get any query params from the URI and return them as an object to initialize the reducer
   */
  function initializeSearchGraphemes() {
    const secondaryHandshapeId = parseInt(query.get('secondary_handshape_id'));
    const primaryHandshapeId = parseInt(query.get('primary_handshape_id'));
    const locationId = parseInt(query.get('location_id'));
    const primaryMovementId = parseInt(query.get('primary_movement_id'));
    const secondaryMovementId = parseInt(query.get('secondary_movement_id'));

    return {
      secondaryHandshape: getHandshape(secondaryHandshapeId) || null,
      primaryHandshape: getHandshape(primaryHandshapeId) || null,
      location: locations.find(handshape => {return handshape.id === parseInt(locationId)}) || null,
      primaryMovement: movements.find(handshape => {return handshape.id === parseInt(primaryMovementId)}) || null,
      secondaryMovement: movements.find(handshape => {return handshape.id === parseInt(secondaryMovementId)}) || null
    };
  }

  /**
   * Manage the states of the selected search graphemes, as well as the URI search params
   * Any time the state is changed, we want to:
   *  1. Get the full info about the grapheme object from the .json data file from the id passed to the reducer via `target`
   *  2. Log the action in Google Analytics
   *  3. Update the search parameters in the URI with the new data
   *  4. Return the updated state object
   * 
   * @param state
   * @param {type: string, target: string|int} action - Action type: the element to update e.g. SET_SECONDARY_HANDSHAPE. Action target: the id of the grapheme.
   * @returns {{}|{secondaryMovement}|{primaryMovement}|{primaryHandshape: *}|{location}|{secondaryHandshape: *}} - The updated Grapheme Search state object
   */
  
  function graphemeSearchReducer(state, action) {
    switch (action.type) {
      case 'SET_SECONDARY_HANDSHAPE':
        const secondaryHandshapeParent = handshapes.find(handshape => handshape.id === parseInt(action.target.split('.')[0]));
        const secondaryHandshape = secondaryHandshapeParent.graphemes.find(grapheme => grapheme.id === parseInt(action.target.split('.')[1]));
        updateSearchParams('secondary_handshape_id', secondaryHandshape ? secondaryHandshape.id : secondaryHandshapeParent.graphemes[0].id);
        logSearchQueryInGA('Add Grapheme','Secondary Handshape', secondaryHandshape ? secondaryHandshape.display_name : secondaryHandshapeParent.graphemes[0].display_name);
        return {...state, secondaryHandshape: secondaryHandshape || secondaryHandshapeParent.graphemes[0] };
      case 'SET_PRIMARY_HANDSHAPE':
        const primaryHandshapeParent = handshapes.find(handshape => handshape.id === parseInt(action.target.split('.')[0]));
        const primaryHandshape = primaryHandshapeParent.graphemes.find(grapheme => grapheme.id === parseInt(action.target.split('.')[1]));
        updateSearchParams('primary_handshape_id', primaryHandshape ? primaryHandshape.id : primaryHandshapeParent.graphemes[0].id);
        logSearchQueryInGA('Add Grapheme','Primary Handshape', primaryHandshape ? primaryHandshape.display_name : primaryHandshapeParent.graphemes[0].display_name);
        return {...state, primaryHandshape: primaryHandshape || primaryHandshapeParent.graphemes[0]};
      case 'SET_LOCATION':
        const location = locations.find(location => location.id === parseInt(action.target));
        updateSearchParams('location_id', location.id);
        logSearchQueryInGA('Add Grapheme','Location', location.display_name);
        return {...state, location: location};
      case 'SET_PRIMARY_MOVEMENT':
        const primaryMovement = movements.find(movement => movement.id === parseInt(action.target));
        updateSearchParams('primary_movement_id', primaryMovement.id);
        logSearchQueryInGA('Add Grapheme', 'Primary Movement', primaryMovement.display_name);
        return {...state, primaryMovement: primaryMovement};
      case 'SET_SECONDARY_MOVEMENT':
        const secondaryMovement = movements.find(movement => movement.id === parseInt(action.target));
        updateSearchParams('secondary_movement_id', secondaryMovement.id);
        logSearchQueryInGA('Add Grapheme','Secondary Movement', secondaryMovement.display_name);
        return {...state, secondaryMovement: secondaryMovement };
      case 'CLEAR_GRAPHEME_SEARCH':
        // Clear all grapheme search elements
        if (action.searchCategory === 'all') {
          if (locationService.pathname === '/search') {
            window.location.replace('/')
          }
          logSearchQueryInGA('Clear Grapheme','all');
          return {}
        } 
        // If clearing a primary element, also clear the secondary one
        else if (action.searchCategory.includes('primary')) {
          const secondarySearchCategory = action.searchCategory.replace('primary', 'secondary');
          delete state[secondarySearchCategory];
          query.delete(`${camelToSnake(secondarySearchCategory)}_id`);
        }
        // Clear the grapheme element
        logSearchQueryInGA('Clear Grapheme', action.searchCategory);
        delete state[action.searchCategory];
        query.delete(`${camelToSnake(action.searchCategory)}_id`);
        window.location.replace(`/search?${query.toString()}`);
        return {...state, };
      default:
        console.log('No action provided');
        return state;
    }
  }

  /***********************
   * LIFECYCLE METHODS
   ***********************/

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleMenuClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleMenuClick);
    };
  });

  /*****************
   * UTILITY METHODS
   *****************/

  /**
   * Since the grapheme is a subelement, we need to know how to transverse the `handshapes` data object. Yes, it's 
   * gross, and could probably be done better.
   * @param handshapeId
   */
  function getHandshape(handshapeId) {

    let parentHandshapeId;
    handshapes.forEach((handshape) => {
      handshape.graphemes.forEach((grapheme) => {
        if (grapheme.id === parseInt(handshapeId)) {
          parentHandshapeId = handshape.id;
        }
      })
    });

    if (parentHandshapeId) {
      const handshapeParent = handshapes.find(handshape => handshape.id === parentHandshapeId);
      return handshapeParent.graphemes.find(grapheme => grapheme.id === handshapeId);
    }
  }

  function logSearchQueryInGA(action, category, value) {
    ReactGA.event({
      category: 'Search',
      action: `${category}: ${action}  ${value}`,
    });
  }


  /***********************
   * HANDLER METHODS
   ***********************/

  /**
   * Handle any sort of click on the dropdown.
   * This is a little bit more complicated than it normally would be, because it needs to also know if there
   * is a click outside of the menu in order to close it.
   *
   * @param event
   */
  function handleMenuClick(event) {
    // Handle any click inside the menu
    if (menuContainerRef.current.contains(event.target)) {

      // To keep away from repeating id's, we prefix the menu element with <menuCategory>--id
      const target = event.target.id.split('--')[1];
      const name = event.target.getAttribute('name');

      if (name === 'topLevelMenuElement') {
        setFocusedSearchCategory(target === focusedSearchCategory ? null : target);
        setActivateMobileTarget(null);
      } else if (['secondaryHandshape', 'primaryHandshape', 'location', 'primaryMovement', 'secondaryMovement'].includes(name)) {
        // See comment above in initialization about how dumb it is to have to do this :/
        if (isIOS && activateMobileTarget !== target) {
          setActivateMobileTarget(target);
        } else {
          // This might be going a little overboard to get the actions to match up with the convention for
          // reducer action naming, but oh well
          const action = `SET_${camelToSnake(name).toUpperCase()}`;
          graphemeSearchDispatch({type: action, target});
          setActivateMobileTarget(null);
        }
      } else {
        setActivateMobileTarget(null);
        setFocusedSearchCategory(null);
        setFocusedDropdownMenuElementId(null);
        setFocusedSubmenuElementId(null);
      }
      return;
    }
    // Handle click outside of the menu to close it
    if (focusedSearchCategory) {
      setActivateMobileTarget(null);
      setFocusedSearchCategory(null)
    }
  }

  /**
   * This is similar to onHover, although it might be different between desktop and mobile
   * @param elementId
   */
  function handleDropdownItemFocus(elementId) {
    setFocusedDropdownMenuElementId(elementId);
  }

  /**
   * This is similar to onMouseLeave
   */
  function handleDropdownItemUnfocus() {
    setFocusedDropdownMenuElementId(null);
    setFocusedSubmenuElementId(null);
  }

  /**
   * This is similar to handleDropdownItemFocus, although for a submenu element.
   * The format of `elementId.subelementId` might be a dumb way to do it, sorry.
   * @param elementAndSubElementIds
   */
  function handleSubmenuDropdownItemFocus(elementAndSubElementIds) {
    setFocusedSubmenuElementId(elementAndSubElementIds.split('.')[1]);
  }

  /**
   * Same as above, but for submenu item
   */
  function handleSubmenuDropdownItemUnfocus() {
    setFocusedSubmenuElementId(null);
  }

  function handleClearGraphemeSearch(searchCategory) {
    graphemeSearchDispatch({type: 'CLEAR_GRAPHEME_SEARCH', searchCategory})
  }


  /***********************
   * RENDER METHODS
   ***********************/

  return (
    <PrimaryMenu
      // Dropdown Component
      menuContainerRef={menuContainerRef}
      graphemeSearch={graphemeSearch}
      // graphemeSearchDispatch={graphemeSearchDispatch}
      focusedSearchCategory={focusedSearchCategory} 
      handleClearGraphemeSearch={handleClearGraphemeSearch}
      
      // SubDropdown Component
      handleDropdownItemFocus={handleDropdownItemFocus}
      handleDropdownItemUnfocus={handleDropdownItemUnfocus}
      focusedDropdownMenuElementId={focusedDropdownMenuElementId}
      handleSubmenuDropdownItemUnfocus={handleSubmenuDropdownItemUnfocus}
      handleSubmenuDropdownItemFocus={handleSubmenuDropdownItemFocus}
      focusedSubmenuElementId={focusedSubmenuElementId}
    />
  );
}

export default GraphemeSearchBar;