import React, { useEffect } from 'react';
import { Player, ControlBar, BigPlayButton, PlayToggle, PlaybackRateMenuButton, ReplayControl, ProgressControl, FullscreenToggle } from 'video-react';
import "video-react/dist/video-react.css";
import "./Video.css";
import HLSSource from './HLSSource';
import TimeDivider from 'video-react/lib/components/time-controls/TimeDivider';
import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay';
import DurationDisplay from 'video-react/lib/components/time-controls/DurationDisplay';

/**
 * Video Component
 *
 * @param {{
 *   showControls: boolean,
 *   shouldPlay: boolean
 *   videoPath: string,
 *   videoThumbnail: string,
 *   autoplay: boolean,
 *   handleFinishedPlaying: function
 * }}props
 * @returns {*}
 * @constructor
 */
const Video = (props) => {

  /**************************
   * Initialization
   **************************/
  let vidRef;
  const isHLS = props.videoPath.includes('.m3u8');

  /**************************
   * LIFECYCLE METHODS
   **************************/

  useEffect(() => {
    if (props.shouldPlay) {
      vidRef.play()
    } else {
      // This is basically a "stop" button. Stop it and reset it to the beginning. (999 seconds seems long enough)
      if (isHLS) {
        vidRef.replay(9999);
        vidRef.pause();
      } else {
        vidRef.load();
      }
    }
    // eslint-disable-next-line
  }, [props.shouldPlay]);


  /**************************
   * STYLES
   **************************/
  const iconStyle = {objectFit: 'contain', borderRadius: '50%'};


  /**************************
   * RENDER METHODS
   **************************/

  function renderHLSSource() {
    return (
      <HLSSource isVideoChild/>
    );
  }

  return (
    <div className="video">
      <Player
        playsInline
        muted={true}
        src={props.videoPath}
        onEnded={props.handleFinishedPlaying}
        poster={props.videoThumbnail}
        autoPlay={props.autoplay || props.shouldPlay}
        ref={(player) => vidRef = player}
        className={props.showControls ? null : 'no-controls'}
        style={props.type === 'icon' ? iconStyle : {objectFit: 'fill'}}
      >
        <BigPlayButton className={props.showControls ? null : 'big-play-button-hide'}/>
        {isHLS ? renderHLSSource() : null}
        <ControlBar
          autoHide={true}
          disableDefaultControls={true}
          disableCompletely={!props.showControls}
        >
          <PlayToggle />
          <ReplayControl seconds={10}/>
          <CurrentTimeDisplay/>
          <TimeDivider/>
          <DurationDisplay/>
          <ProgressControl/>
          <PlaybackRateMenuButton rates={[2, 1.75, 1.5, 1.25, 1, 0.75, 0.5]} />
          <FullscreenToggle/>
        </ControlBar>
      </Player>
    </div>
  )
};

export default Video;