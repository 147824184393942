import React from 'react';

import './BrowserError.css';

const BrowserError = () => {
    return (
        <div className="container">
            <div className="message">
                <img id="main-logo" src="https://aslclear-webapp-prod.s3.us-east-2.amazonaws.com/build/logo.png" alt="colorful tree"></img>
                <h2>Chrome is an unsupported browser at this time.</h2>
                <h2>ASL Clear is currently available on these browsers:</h2>
                <div className="images">
                    <img className="browserLogo" src='/images/Safari_logo.png' alt="Safari"></img>
                    <img className="browserLogo" src='/images/Firefox_logo.png' alt="Firefox"></img>
                </div>
            </div>
            <div class="footer">
                <p className='footer-text'>Managed by<span className="AEC-name"> ASL Ed Center, LLC</span></p>
                <div class="social-icons">
                    <a href="https://www.asledcenter.org/" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-globe"></i></a>
                    <a href="https://www.facebook.com/ASLClear" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-facebook"></i></a>
                    <a href="https://www.instagram.com/aslclear" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram"></i></a>
                </div>
            </div>
        </div>
    )
}

export default BrowserError