import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';

import log from '../../utils/logger';

// Components
import VideoNavigation from '../shared/video/VideoNavigation';
import LanguageContext from '../../context/language-context';
import { FlashMessageContext } from '../../context/flash-message';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";
import Breadcrumbs from '../shared/breadcrumbs/Breadcrumbs';


/**
 * This could also be called the Lectures component, 
 * since a Topic is really just a list of lectures. 
 * If we decide to add more things to a topic though, 
 * and to keep in line with the URI of this element, Topic seems to make a
 * little bit more sense.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function Topic(props) {

  const { categoryName, topicName } = props.match.params;

  const [isOnlyTopicInCategory, setIsOnlyTopicInCategory] = useState(false)
  const [topicData, setTopicData] = useState(null);
  const languageContext = useContext(LanguageContext);
  const flashMessageContext = useContext(FlashMessageContext);

  const apiUri = process.env.REACT_APP_API_PATH;

  /***********************
   * LIFECYCLE METHODS
  ***********************/
  useEffect(() => {

    getTopic()
      .then((topic) => {
        if (topic) {
          setTopicData(topic);
        }
      });

    
    getCategoryTopics()
      .then((topicsResponse) => {
        // Since some categories have only one topic, there is a function that sends it directly to this page
        // if that is the case. With that, the back button wouldn't work, so we need to go further back.
        if (topicsResponse.topics.length === 1) {
          setIsOnlyTopicInCategory(true)
        }
      });
    // eslint-disable-next-line
  }, []);


  /***********************
   * HTTP METHODS
  ***********************/

  function getTopic() {
    return axios({
      url: `${apiUri}/topics/${topicName}`,
      method: 'GET',
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        log.error(`GET TOPIC: ${error}`);
        flashMessageContext.showMessage(`We could not find this topic: ${topicName}. Please try search different topic.`);
      })
  }

  function getCategoryTopics() {
    return axios({
      url: `${apiUri}/topics?category_name=${categoryName}`,
      method: 'GET',
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        log.error(`GET CATEGORY: ${error}`)
        flashMessageContext.showMessage(`We could not find topic(s) in ${categoryName}.`);
      })
  }


  /***********************
   * RENDER METHODS
   ***********************/

  function renderListLectures() {
    if (topicData) {
      return (
        <Row className='justify-content-center'>
          {topicData.lectures.map(lecture => {
            // TODO: What do we do for something without a preview video?
            if (lecture.preview) {
              return (
                <Col xs={12} 
                     md={6} 
                     lg={4} 
                     className="text-center mb-3" 
                     key={lecture.id} 
                     aria-label={`Link to Lecture: ${lecture.display_name}`}
                >
                  <VideoNavigation
                    {...props}
                    videoPath={lecture.preview.mp4_uris.low}
                    videoThumbnail={lecture.thumbnail}
                    type='box'
                    link={`/categories/${categoryName}/topics/${topicName}/lectures/${lecture.name}`} // This should probably be send as a prop
                  />
                  {languageContext.showEnglish &&
                    <p>{lecture.display_name}</p>
                  }
                </Col>
              );
            } else {
              console.log(`No lecture preview for ${lecture.name}`)
              return null;
            }
          })}
        </Row>
      );
    }
  }

  // Main render
  if (!topicData) {
    return <div className="justify-content-md-center my-3 text-center">Loading...</div>
  }
  return (
    <Fragment>
      <Helmet>
        <title>ASL Clear | {topicData && topicData.display_name}</title>
      </Helmet>
      <Row className='mb-4'>
        <Col xs={12}>
          <Breadcrumbs level={isOnlyTopicInCategory ? 'category' : 'topic'} category={categoryName} topic={topicName}/>
        </Col>
      </Row>
      {renderListLectures()}
    </Fragment>
  )
}

export default Topic;