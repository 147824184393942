import React, { useState } from 'react';

import {handshapes} from '../data/graphemes.json'

import './graphemes.css';

function Handshape(props) {

  /***********************
   * INITIALIZATION
  ***********************/
  const [iconWidth, setIconWidth] = useState(0);

  const parentId = props.parentId;
  const handshapeId = props.id;

  const handshapeGroup = handshapes.find(parentHandshape => parentHandshape.id === parentId);
  const handshape = handshapeGroup.graphemes.find(handshape => handshape.id === handshapeId);

  /***********************
   * LIFECYCLE METHODS
  ***********************/

  function onIconLoad(input) {
    setIconWidth(input.target.clientWidth)
  }


  /***********************
   * STYLES
  ***********************/

  const subhandshapeContainerStyle = {
    left: ((iconWidth) - (handshape.subHandshapes.length * iconWidth)) / 2
  }

  const subhandshapeFlexContainerStyle = {
    position: 'relative',
    display: 'flex'
  }


  /***********************
   * RENDER METHODS
  ***********************/

  function renderGrapheme() {
    return (
      <div>
        <img src={handshape.imagePath}
             alt={handshape.name}
             className='img-fluid border'
             onLoad={onIconLoad}
        />
      </div>
    );
  }

  function renderSubhandshapes() {
    return (
      <div className="subhandshape-display-container" style={subhandshapeContainerStyle}>
        <div style={subhandshapeFlexContainerStyle}>
          {
            handshape.subHandshapes.map(subhandshape => {
              return (
                <img src={subhandshape.imagePath}
                     alt={subhandshape.name}
                     key={subhandshape.name}
                     width={iconWidth}
                />
              )
            })
        }
        </div>
      </div>
    )
  }

  return (
    <div className="handshape-container">
      {renderGrapheme()}
      {renderSubhandshapes()}
    </div>
  )

}

export default Handshape;