import React, { Fragment, useState, useContext } from "react";

import { Helmet } from "react-helmet";

import axios from "axios";

// Bootstrap 
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

// Contexts
import LanguageContext from "../../context/language-context";
import { FlashMessageContext } from '../../context/flash-message';

import log from '../../utils/logger';

// Components
import Video from "../shared/video/Video";

function FAQ() {
  /**********************
   * INITIALIZATION
   **********************/
  const apiUri = process.env.REACT_APP_API_PATH;

  const flashMessageContext = useContext(FlashMessageContext);

  const languageContext = useContext(LanguageContext);
  const [feedbackFormValidated, setFeedbackFormValidated] = useState(false);
  const [feedbackFormSubmitted, setFeedbackFormSubmitted] = useState(false);


  /**********************
   * HTTP METHODS
   **********************/

  function submitFeedback(type, message) {
    return axios({
      url: `${apiUri}/feedback`,
      method: 'POST',
      data: { 'type': type, 'message': message },
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(response => response)
      .catch(error => {
        log.error(`SUBMIT FEEDBACK: ${error}`);
        flashMessageContext.showMessage('There was an error connecting to the server. Could not submit feedback.');
      })
  }


  /**********************
   * HANDLER METHODS
   **********************/

  function handleSubmitFeedback(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setFeedbackFormValidated(true);
  
      const type = form.formFeedbackType.value;
      const message = form.formMessage.value;
  
      submitFeedback(type, message)
        .then(() => {
          setFeedbackFormSubmitted(true)
        });
    }
  }


  /**********************
   * RENDER METHODS
   **********************/

  function renderGeneralInstructions() {
    return (
      <section>
        <Row className='justify-content-center text-center align-items-center'>
          <Col md={8}>
            {languageContext.showEnglish && <h2>How to use ASL Clear</h2>}
            <Video showControls={true} videoPath='/videos/faq/general-instruction.mp4'/>
          </Col>
        </Row>
      </section>
    )
  }

  function renderGraphemeSearchInstructions() {
    return (
      <section>
        <Row className='justify-content-center'>
          <Col md={8} className='mb-3 text-center'>
            {languageContext.showEnglish && <h2>How to use the Grapheme Search</h2>}
            <img className='border border-default img-fluid' alt='grapheme sample' src='/images/faq/graphemes.png'/>
          </Col>
        </Row>
        <Row className='align-items-center mb-2'>
          <Col xs={4} className='text-right'>
            <img className='border border-default' alt='grapheme handshape' height='100px' width='100px' src='images/graphemes/grapheme_search_handshape.png'/>
            {languageContext.showEnglish && (<span><br/>Handshape</span>)}
          </Col>
          <Col xs={8} md={6}>
            <Video showControls={true} videoPath='/videos/faq/handshape-instructions.mp4'/>
          </Col>
        </Row>
        <Row className='align-items-center mb-2'>
          <Col xs={4} className='text-right'>
            <img className='border border-default' alt='grapheme location' height='100px' width='100px' src='images/graphemes/grapheme_search_location.svg'/>
            {languageContext.showEnglish && (<span><br/>Location</span>)}
          </Col>
          <Col xs={8} md={6}>
            <Video showControls={true} videoPath='/videos/faq/location-instructions.mp4'/>
          </Col>
        </Row>
        <Row className='align-items-center mb-2'>
          <Col xs={4} className='text-right'>
            <img className='border border-default' alt='grapheme movement' height='100px' width='100px' src='images/graphemes/grapheme_search_movement.png'/>
            {languageContext.showEnglish && (<span><br/>Movement</span>)}
          </Col>
          <Col xs={8} md={6}>
            <Video showControls={true} videoPath='/videos/faq/movement-instructions.mp4'/>
          </Col>
        </Row>
      </section>
    )
  }

  function renderFeedbackForm() {
    if (!feedbackFormSubmitted) {
      return (
        <section>
          <Row className='justify-content-center'>
            <Col md={6}>
              <h1 className='text-center mb-3'>
                {languageContext.showEnglish ? 'Questions or Feedback?' : <FontAwesomeIcon icon={faComments}/>}
              </h1>
              <Form noValidate validated={feedbackFormValidated} onSubmit={handleSubmitFeedback}>
                <Form.Group controlId="formFeedbackType">
                  <Form.Label>Contact us about...</Form.Label>
                  <Form.Control required as="select">
                    <option/>
                    <option value='feedback'>General feedback</option>
                    <option value='suggestion'>Suggestion for the site</option>
                    <option value='question'>Questions about the site</option>
                    <option value='error'>A problem with the site</option>
                    <option value='other'>Other</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control required as="textarea" rows='4'/>
                </Form.Group>
                <div className='text-center'>
                  <Button type="submit">Submit feedback</Button>
                </div>
              </Form>
            </Col>
          </Row>
        </section>
      )
    } else {
      return (
        <p className='alert alert-success text-center'>Thank you for your feedback!</p>
      )
    }
  }

  function renderPage() {
    return(
      <div className='mb-5'>
        {languageContext.showEnglish && <h1>FAQs</h1>}
        {renderGeneralInstructions()}
        <hr className='my-5'/>
        {renderGraphemeSearchInstructions()}
        <hr className='my-5'/>
        {renderFeedbackForm()}
      </div>
    )
  }

  return (
    <Fragment>
      <Helmet>
        <title>ASL Clear | FAQ</title>
      </Helmet>
      {renderPage()}
    </Fragment>
  )
}

export default FAQ;