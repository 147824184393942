import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import { Container } from "react-bootstrap";

function Acknowledgments() {

  /**********************
   * RENDER METHODS
   **********************/

  function renderPage() {
    return(
      <Container>
        <h1>Acknowledgements</h1>
        <p>ASL Clear was made a reality with the funding from the Massachusetts Department of Elementary and Secondary Education (award #16CT83BU16SEPPB1718). This online STEM library in ASL and application flourished due to a large collaborative team effort between Boston University's Center for the Study of Communication and the Deaf and the following authors, editors, and researchers: </p>
        <h1>Authors</h1>
        <ul>
          <li>Robert Hoffmeister, PhD, Founder</li>
          <li>Jeanne Reis, Project Investigator</li>
          <li>Yonatan Laurence, Lead Platform Developer</li>
        </ul>
        <p>ASL Clear was first envisaged by Jeanne Reis with critical support from Dr. Robert Hoffmeister. Research and the development of ASL Clear materials began in 2005, and ASL Clear's online ASL-centric app delivering STEM modules in ASL was released in 2017. Since its release, ASL Clear has been recognized as an innovative web design that allows users to navigate, search, and utilize the app entirely in ASL.</p>
        <p>The ASL Clear corpus has been enriched by data and research in collaboration with many panels of subject matter experts who are Deaf and native or heritage ASL signers.</p>
        <h1>The American Deaf Community</h1>
        <p>Deep appreciation goes to the Deaf Community across the United States as ASL Clear wouldn't exist without the support, enthusiasm, and participation of Deaf people, professionals, and the Deaf community at large. Many educators, experts, schools serving deaf students, and deaf-centered organizations across the USA provided critical input and expertise along the way. We thank each individual and entity that shared their knowledge and language with the aim of providing cutting edge learning tools for deaf learners of all ages.</p>
        <p>As there have been countless contributors to the ASL Clear project over the years, we are not able to list them here. If you have been part of this project to date, please know that you are greatly appreciated.</p>
        <h1>Image Attributions</h1>
        <p>Some lecture thumbnails used images from the following sources from WikiMedia Commons.</p>
        <ul>
          <li>Dr. Randi Rotjan, New England Aquarium. www.neaq.org, <a href="https://commons.wikimedia.org/wiki/File:Rotjan_-_Enderbury_Day_1_-_2nd_half_(62).JPG">Rotjan - Enderbury Day 1 - 2nd half (62)</a>, Color and contrast <a href="https://creativecommons.org/licenses/by-sa/3.0/legalcode" rel="license">CC BY-SA 3.0</a></li>
          <li>Jerzy Strzelecki, <a href="https://commons.wikimedia.org/wiki/File:Antarctic_(js)_18.jpg">Antarctic (js) 18</a>, Color and contrast <a href="https://creativecommons.org/licenses/by/3.0/legalcode" rel="license">CC BY 3.0</a></li>
          <li><a href="https://commons.wikimedia.org/wiki/User:Ibex73">Ibex73</a>, <a href="https://commons.wikimedia.org/wiki/File:Ibex_in_the_French_Vanoise_National_Park.JPG">Ibex in the French Vanoise National Park</a>, Color and contrast <a href="https://creativecommons.org/licenses/by-sa/4.0/legalcode" rel="license">CC BY-SA 4.0</a></li>
          <li>Marc Ryckaert (<a href="https://commons.wikimedia.org/wiki/User:MJJR">MJJR</a>), <a href="https://commons.wikimedia.org/wiki/File:Marsa_Alam_R18.jpg">Marsa Alam R18</a>, Color and contrast <a href="https://creativecommons.org/licenses/by/3.0/legalcode" rel="license">CC BY 3.0</a></li>
          <li><a href="https://commons.wikimedia.org/wiki/User:Cschirp">Cschirp</a>, <a href="https://commons.wikimedia.org/wiki/File:Testpad.JPG">Testpad</a>, Color and contrast <a href="https://creativecommons.org/licenses/by-sa/3.0/legalcode" rel="license">CC BY-SA 3.0</a></li>
          <li><a href="https://www.flickr.com/people/94466642@N00">steverenouk</a>, <a href="https://commons.wikimedia.org/wiki/File:Exe_estuary_from_balloon.jpg">Exe estuary from balloon</a>, Color and contrast <a href="https://creativecommons.org/licenses/by-sa/2.0/legalcode" rel="license">CC BY-SA 2.0</a></li>
        </ul>
      </Container>
    )
  }

  return (
    <Fragment>
      <Helmet>
        <title>ASL Clear | Acknowledgements</title>
      </Helmet>
      {renderPage()}
    </Fragment>
  )
}

export default Acknowledgments;