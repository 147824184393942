import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import BrowserError from './BrowserError';
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function initialize(component) {
    ReactDOM.render(component, document.getElementById('root'));
}

const isBrowserProblematic = () => {
    /* Taken from https://stackoverflow.com/a/9851769 */
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Chrome 1 - 79
    const isChrome = !!window.chrome;
    // Edge (based on chromium) detection
    const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

    return isChrome || isOpera || isEdgeChromium
}

const LandingSite = isBrowserProblematic() ? BrowserError : App

initialize(<LandingSite />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
