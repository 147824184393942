import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import Autosuggest from 'react-autosuggest';

import Form from "react-bootstrap/Form";
import log from '../../../utils/logger';
import InputGroup from "react-bootstrap/InputGroup";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import '../css/NavBar.css'

function EnglishSearchBar() {

  /***********************
   * INITIALIZATION
   ***********************/

  let history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const apiUri = process.env.REACT_APP_API_PATH;

  const [englishTags, setEnglishTags] = useState(null);

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);


  /**********************
   * LIFECYCLE METHODS
   **********************/

  useEffect(() => {
    getEnglishTags()
      .then(results => {
        setEnglishTags(results && results.english_tags);
      })
    // eslint-disable-next-line
  }, []);


  /***********************
   * UTILITY METHODS
   ***********************/

    // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = value => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 || !englishTags ? [] : englishTags.filter(lang =>
        lang.toLowerCase().slice(0, inputLength) === inputValue
      );
    };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion;

  const onChange = (event, {newValue}) => {
    setValue(newValue)
  };

  // Will be called every time you need to recalculate suggestions.
  const onSuggestionsFetchRequested = ({value}) => {
    setSuggestions(getSuggestions(value))
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  // Will be called every time suggestion is selected via mouse or keyboard.
  const onSuggestionSelected = (event, {suggestion}) => {
    query.delete('primary_movement_id');
    query.delete('secondary_movement_id');
    query.delete('location_id');
    query.delete('primary_handshape_id');
    query.delete('secondary_handshape_id');
    history.push(`/search/?english_tags=${suggestion}`);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Search',
    value,
    onChange: onChange
  };


  /***********************
   * HTTP METHODS
   ***********************/

  async function getEnglishTags() {
    return axios({
      url: `${apiUri}/english_tags`,
      method: 'GET',
      headers: {'Access-Control-Allow-Origin': '*'}
    })
      .then((response) => {
        return response.data
      })
      .catch(error => {
        log.error(`GET ENGLISH TAGS: ${error}`);
      });
  }


  /***********************
   * HANDLER METHODS
   ***********************/

  function handleSubmitSearch(e) {
    query.delete('primary_movement_id');
    query.delete('secondary_movement_id');
    query.delete('location_id');
    query.delete('primary_handshape_id');
    query.delete('secondary_handshape_id');
    e.preventDefault();
    history.push(`/search/?english_tags=${value}`);
  }


  /***********************
   * RENDER METHODS
   ***********************/

  function renderSuggestion (suggestion) {
    return (
      <span>
        {suggestion}
      </span>
    );
  }

  return (
    <div className='mx-auto' aria-label='Search by English tags'>
      <Form inline onSubmit={handleSubmitSearch}>
        <Form.Group>
          <InputGroup>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              highlightFirstSuggestion={true}
              inputProps={inputProps}
            />
            <InputGroup.Append style={{cursor: 'pointer'}} onClick={handleSubmitSearch}>
              <InputGroup.Text id={'inputGroupAppend'}>
                <FontAwesomeIcon icon={faSearch}/>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    </div>
  );
}

export default EnglishSearchBar;