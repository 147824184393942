import React, { useEffect } from 'react';
import './App.css';

import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Helmet } from "react-helmet";

import ReactGA from 'react-ga';
import Container from 'react-bootstrap/Container';

// Context
import { LanguageProvider } from './context/language-context';
import { FlashMessageContextProvider } from './context/flash-message';
import { IsMobileContextProvider } from './context/isMobile-context';

// Components
import NavBar from './components/nav/NavBar';
import Category from './components/category/Category';
import Categories from './components/category/Categories';
import SearchResults from './components/searchResults/SearchResults';
import Topic from './components/topic/Topic';
import Term from './components/term/Term';
import Lecture from './components/lecture/Lecture';
import Footer from './components/shared/Footer';
import FlashMessage from './components/FlashMessage';
import FAQ from './components/faq/FAQ';
import Acknowledgments from './components/Acknowledgments/Acknowledgments';
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

function App() {

  /****************
   INITIALIZATION
   ****************/

  const history = createBrowserHistory();

  /****************
   LIFECYCLE METHODS
   ****************/

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // eslint-disable-next-line
  }, [window.location.pathname, window.location.search]);

  /****************
   RENDER METHODS
   ****************/

  return (
    <FlashMessageContextProvider>
      <LanguageProvider>
        <IsMobileContextProvider>
          <Router history={history}>
            <Helmet>
              <title>ASL Clear</title>
            </Helmet>
            <NavBar />
            <main>
              <Container style={{ minHeight: '90vh' }}>
                <FlashMessage />
                <Switch>
                  <Route path="/" exact render={props => <Categories {...props} />} />
                  <Route path='/search' exact component={SearchResults} />
                  <Route path='/categories/:categoryName/' exact component={Category} />
                  <Route path='/categories/:categoryName/topics/:topicName' exact component={Topic} />
                  <Route path='/categories/:categoryName/topics/:topicName/lectures/:lectureName' exact component={Lecture} />
                  <Route path='/terms/:termName' exact component={Term} />
                  <Route path='/faq' exact component={FAQ} />
                  <Route path='/acknowledgements' exact component={Acknowledgments} />
                  <Route path='/app' component={LegacyURICatcher} />
                  <Route component={NotFound} />
                </Switch>
              </Container>
            </main>
          </Router>
          <Footer />
        </IsMobileContextProvider>
      </LanguageProvider>
    </FlashMessageContextProvider>
  );
}

/****************
 MINI COMPONENTS
****************/

function LegacyURICatcher() {
  return <Redirect to="/" />
}

function NotFound({ location }) {
  return (
    <div>
      <h3>
        Not Found: <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;
