import React, { Fragment, useEffect, useState, useContext } from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import VideoNavigation from '../shared/video/VideoNavigation';
import LanguageContext from '../../context/language-context';
import { FlashMessageContext } from '../../context/flash-message';

import log from '../../utils/logger';

import axios from 'axios';
import { Helmet } from "react-helmet";
import Breadcrumbs from '../shared/breadcrumbs/Breadcrumbs';


/**
 * This could also be called the Topics component, since a Category is really just a list of topics. If we decide
 * to add more things to a category though, and to keep in line with the URI of this element, Category seems to make a
 * little bit more sense.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function Category(props) {

  const { categoryName } = props.match.params;

  const [categoryData, setCategoryData] = useState(null);

  const languageContext = useContext(LanguageContext);
  const flashMessageContext = useContext(FlashMessageContext);

  const apiUri = process.env.REACT_APP_API_PATH;


  /***********************
   * LIFECYCLE METHODS
  ***********************/
  useEffect(() => {

    getCategory()
      .then((categoryResponse) => {
        setCategoryData(categoryResponse)
      });
    // eslint-disable-next-line
  }, []);


  /***********************
   * HTTP METHODS
  ***********************/

  function getCategory() {
    return axios({
      url: `${apiUri}/categories/${categoryName}`,
      method: 'GET',
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        log.error(`GET CATEGORY: ${error}`)
        flashMessageContext.showMessage(`We could not find this category: ${categoryName}.`);
      })
  }


  /***********************
   * RENDER METHODS
   ***********************/

  function renderTopics() {
    if (categoryData) {
      return (
        <Row className='circleVideo justify-content-center'>
          {categoryData.topics.map(topic => {
            return (
              <Col xs={6} md={4} lg={3} className="text-center mb-2" key={topic.id} aria-label={`Link to Topic: ${topic.display_name}`}>
                <VideoNavigation
                  {...props}
                  imageIcon={`/images/topic-and-category-icons/${topic.name}.png`}
                  videoPath={`/videos/topic-and-category-icons/${topic.name}.mp4`}
                  type='icon'
                  link={`/categories/${categoryName}/topics/${topic.name}`}
                />
                {languageContext.showEnglish &&
                  <p>{topic.display_name}</p>
                }
              </Col>
            );
          })}
        </Row>
      );
    }
  }

  // Main render method
  if (!categoryData) {
    return <div className="justify-content-md-center my-3 text-center">Loading...</div>
  } else {
    return (
      <Fragment>
        <Helmet>
          <title>ASL Clear | {categoryData && categoryData.display_name}</title>
        </Helmet>
        <Row className='mb-4'>
          <Col xs={12}>
            <Breadcrumbs level='category' category={categoryName}/>
          </Col>
        </Row>
        {renderTopics()}
      </Fragment>
    );
  }
}

export default Category;