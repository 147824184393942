import React, { useContext, useEffect, useState, Fragment } from "react";

import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { Link } from "react-router-dom";

import log from '../../utils/logger';

// Bootstrap 
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// Contexts
import LanguageContext from "../../context/language-context";
import { FlashMessageContext } from '../../context/flash-message';

// Components
import VideoNavigation from "../shared/video/VideoNavigation";
import Lexemes from "../lexeme/Lexemes";

function SearchResults(props) {

  /***********************
   * INITIALIZATION
   ***********************/

  const apiUri = process.env.REACT_APP_API_PATH;
  const query = new URLSearchParams(useLocation().search);

  const languageContext = useContext(LanguageContext);
  const flashMessageContext = useContext(FlashMessageContext);

  const [englishTerm, setEnglishTerm] = useState(query.get('english_tags'));
  const [termsSearchResults, setTermsSearchResults] = useState(null);
  const [termSuggestionSubmitted, setTermSuggestionSubmitted] = useState(false);


  /**********************
   * LIFECYCLE METHODS
   **********************/

  useEffect(() => {
    updateSearchParams()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEnglishTerm(query.get('english_tags'));
    setTermSuggestionSubmitted(false);
    updateSearchParams()
    // eslint-disable-next-line
  }, [window.location.search]);

  function updateSearchParams() {

    let graphemeSearchQuery = {};
    if (query.get('secondary_handshape_id')) {
      // In the API, it's "glyph" rather than "grapheme"
      graphemeSearchQuery.secondary_handshape_id = query.get('secondary_handshape_id');
    }
    if (query.get('primary_handshape_id')) {
      graphemeSearchQuery.primary_handshape_id = query.get('primary_handshape_id');
    }
    if (query.get('location_id')) {
      graphemeSearchQuery.location_id = query.get('location_id');
    }
    if (query.get('primary_movement_id')) {
      graphemeSearchQuery.primary_movement_id = query.get('primary_movement_id');
    }
    if (query.get('secondary_movement_id')) {
      graphemeSearchQuery.secondary_movement_id = query.get('secondary_movement_id');
    }

    // If the user is searching by Glyphs
    if (Object.keys(graphemeSearchQuery).length > 0) {
      getTermsByGraphemes(graphemeSearchQuery)
        .then((terms) => {
          setTermsSearchResults(terms)
        })
    }

    // If the user is using the English search
    else if (query.get('english_tags')) {
      getTermsByEnglishTags(query.get('english_tags'))
        .then((terms) => {
          setTermsSearchResults(terms)
        })
    }

    // If there isn't anything in the search, return to the home page
    else {
      props.history.push('/')
    }
  }


  /**********************
   * HTTP METHODS
   **********************/

  function getTermsByGraphemes(graphemeSearchParams) {
    graphemeSearchParams.fuzzy_search = true;
    return axios({
      url: `${apiUri}/terms`,
      method: 'GET',
      params: graphemeSearchParams,
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then((response) => {
        return response.data && response.data.terms ? response.data.terms : [];
      })
      .catch(error => {
        log.error(`SEARCH BY GRAPHEMES: ${error}`);
        flashMessageContext.showMessage('Network error. Could not find terms.');
        return [];
      });
  }

  function getTermsByEnglishTags(englishSearchParams) {
    return axios({
      url: `${apiUri}/terms`,
      method: 'GET',
      params: { 'english_tags': englishSearchParams },
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then((response) => {
        return response.data && response.data.terms ? response.data.terms : [];
      })
      .catch(error => {
        log.error(`SEARCH BY ENGLISH TAGS: ${error}`);
        flashMessageContext.showMessage('Network error. Could not search by English terms.');
        return [];
      });
  }

  function postTagSuggestion(term) {
    return axios({
      url: `${apiUri}/english_tag_suggestions`,
      method: 'POST',
      data: { 'tag': term },
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(response => response)
      .catch(error => {
        log.error(`SUBMIT ENGLISH TAG SUGGESTION: ${error}`);
        flashMessageContext.showMessage('There was an error connecting to the server. Could not submit suggestion.');
      })
  }


  /**********************
   * EVENT HANDLERS
   **********************/
  async function handlerTagSuggestionClicked(term) {
    if (term) {
      await postTagSuggestion(term);
      setTermSuggestionSubmitted(true);
    }
  }


  /**********************
   * STYLES
   **********************/
  const groupIconStyle = {
    position: "absolute",
    zIndex: 1,
    margin: "5px 10px"
  }


  /**********************
   * RENDER METHODS
   **********************/

  function renderTerms(terms) {
    return(
      <Row>
        {terms.map(term => {
          return term.sign ? renderTerm(term) : null
        })}
      </Row>
    )
  }

  /**
   * Render the icon links to the Category(ies) and Topic(s)
   * @param {*} lectures
   */
  function renderGroupIcons(lectures) {

    // Get unique categories and topics, so we don't show duplicate links
    const categories = [];
    const topics = [];
    
    lectures.forEach(lecture => {
      if (!categories.some(category => category.name === lecture.category_name)) {
        categories.push({name: lecture.category_name})
      }

      if(!topics.some(topic => topic.name === lecture.topic_name)) {
        topics.push({category: lecture.category_name, name: lecture.topic_name})
      }
    });
    
    return (
      <div>
        {categories.map(category => {
          return (
            <Link to={`/categories/${category.name}`} key={category.name}>
              <img 
                src={`/images/topic-and-category-icons/${category.name}.png`}
                alt={category.name}
                style={{"width": "30px", "display": "block"}}
              />
            </Link>
          )
        })}
        
        {topics.map(topic => {
          return(
             <Link to={`/categories/${topic.category}/topics/${topic.name}`} key={topic.name}>
              <img
                src={`/images/topic-and-category-icons/${topic.name}.png`}
                alt={topic.name}
                style={{"width": "30px", "display": "inline-block"}}
              />
            </Link>
          )
        })}
      </div>
    )
  }

  function renderTerm(term) {
    return (
      <Col className={'mb-5'} key={term.name} sm={6} md={6} lg={4}>
        {languageContext.showEnglish ? <h3>{term.english_tags}</h3> : <Fragment/>}
        <div 
          className="category_topics" 
          style={groupIconStyle}
          >
            {renderGroupIcons(term.lectures)}
        </div>
        <VideoNavigation
          {...props}
          type='box'
          videoPath={term.sign.mp4_uris.low}
          videoThumbnail={term.sign.thumbnail_uris.low}
          link={`/terms/${term.name}`}
        />
        <Lexemes lexemesData={term.lexemes} />
      </Col>
    )
  }

  function renderNoResults() {
    let englishText;
    if (termSuggestionSubmitted) {
      englishText = (
        <p>
          Suggestion for <span style={{ fontWeight: "bold" }}>{englishTerm} </span> submmited! Your request is under review.
        </p>
      )
    } else {
      englishText = (
        <p>Unfortunately, no terms were found matching&nbsp;
          <button className="btn btn-secondary btn-sm" onClick={() => handlerTagSuggestionClicked(englishTerm)} >
            {englishTerm}
          </button>. Click to suggest we add it!
        </p>
      )
    }

    return (
      <Fragment>
        <Row className={'justify-content-center'}>
          <Col sm={4}>
            <img src={'/images/no-results.png'} alt={'No results'} style={{ opacity: '50%' }} className={'img-fluid'} />
          </Col>
        </Row>

        <Row className={'justify-content-center text-center'}>
          <Col sm={8}>
            {languageContext.showEnglish && englishText}
          </Col>
        </Row>
      </Fragment>
    )
  }

  if (!termsSearchResults) {
    return (
      <div>Loading...</div>
    )
  } else {
    return (
      <Fragment>
        <Helmet>
          <title>ASL Clear | Search results {languageContext.showEnglish ? ` for ${query.get('english_tags')}` : ' by grapheme'}</title>
        </Helmet>
        {termsSearchResults.length > 0 ? renderTerms(termsSearchResults) : renderNoResults()}
      </Fragment>
    )
  }
}

export default SearchResults;