import React from 'react';

import { useHistory } from "react-router-dom";


import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


function BackButton(props) {

  let history = useHistory();
  function handleClick() {
    if (props.link) {
      history.push(props.link)
    } else {
      history.goBack()
    }
  }

  return (
    <Button variant='outline-info' aria-label="Go back" onClick={handleClick}>
      <FontAwesomeIcon icon={faChevronLeft}/>
    </Button>
  )
}

export default BackButton