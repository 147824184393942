import React, { useEffect, useState } from 'react';

// Bootstrap Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Handshape from './graphemes/Handshape';
import Location from './graphemes/Location';
import Movement from './graphemes/Movement';

/**
 *
 * @param {{
 *  lexemesData: LexemeData[]
 * }} props
 * @returns {*}
 * @constructor
 */
function Lexemes(props) {

  /***********************
   * INITIALIZATION
  ***********************/
  const [isLoaded, setIsLoaded] = useState(false);


  /***********************
   * LIFECYCLE METHODS
  ***********************/
  useEffect(() => {
    setIsLoaded(true);
    // eslint-disable-next-line
  }, []);


  /***********************
   * RENDER METHODS
   ***********************/

  function renderLexemes(lexemes) {
    if (!lexemes) {return <div/>}

    return (
      lexemes.map((lexeme, index) => {
        return (
          <Row className="no-gutters" key={index}>
            <Col>
              {lexeme.handshapes.secondary && 
                <Handshape parentId={lexeme.handshapes.secondary.parent_id}
                           id={lexeme.handshapes.secondary.id}
                />
              }
            </Col>
            <Col>
              <Handshape parentId={lexeme.handshapes.primary.parent_id}
                         id={lexeme.handshapes.primary.id}
              />
            </Col>
            <Col>
              <Location id={lexeme.location.id}/>
            </Col>
            <Col>
              <Movement id={lexeme.movements.primary.id}/>
            </Col>
            <Col>
              {lexeme.movements.secondary && <Movement id={lexeme.movements.secondary.id}/>}
            </Col>
          </Row>
        )
      })
    )
  }

  // Main render
  if (!isLoaded) {
    return <div className="justify-content-md-center my-3 text-center">Loading...</div>
  }
  return (
    renderLexemes(props.lexemesData)
  )
}

/**********************
 * JSDOC DEFINITIONS
 **********************/

/**
 * @typedef LexemeData
 * @property {number} id
 * @property {number} movements.primary.id
 * @property {string} movements.primary.name
 * @property {string} movements.primary.img_path
 * @property {number} movements.secondary.id
 * @property {string} movements.secondary.name
 * @property {string} movements.secondary.img_path
 * @property {number} location.id
 * @property {string} location.name
 * @property {string} location.img_path
 * @property {number} handshapes.primary.id
 * @property {string} handshapes.primary.name
 * @property {string} handshapes.primary.img_path
 * @property {number} handshapes.secondary.id
 * @property {string} handshapes.secondary.name
 * @property {string} handshapes.secondary.img_path
 */

export default Lexemes;

