import React, { useState } from 'react';

import {movements} from '../data/graphemes.json';

function Movement(props) {

  /***********************
   * INITIALIZATION
  ***********************/
 
  const [isFocused, setIsFocused] = useState(false);

  const movement = movements.find(movement => movement.id === props.id);


  /***********************
   * HANDLER METHODS
  ***********************/

  function handleFocus() {
    setIsFocused(true)
  }

  function handleUnfocus() {
    setIsFocused(false);
  }


  /***********************
   * RENDER METHODS
  ***********************/

  function renderGrapheme() {
    return (
      <img src={movement.imagePath} alt={movement.name} className='img-fluid border'/>
    )
  }

  function renderExplanation() {
    return (
      <div className={'embed-responsive embed-responsive-1by1'}>
        <video className="embed-responsive-item" autoPlay={true} >
          <source src={movement.videoPath}/>
        </video>
      </div>
    )
  }

  return (
    <div onMouseEnter={handleFocus} onMouseLeave={handleUnfocus}>
      {isFocused ? renderExplanation() : renderGrapheme() }
    </div>
  )
}

export default Movement;