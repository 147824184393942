import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';

import { Helmet } from 'react-helmet';

// Bootstrap Components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import log from '../../utils/logger';

// Contexts
import IsMobileContext from '../../context/isMobile-context';
import LanguageContext from '../../context/language-context';
import { FlashMessageContext } from '../../context/flash-message';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignLanguage } from '@fortawesome/free-solid-svg-icons';

import Video from '../shared/video/Video';
import VideoNavigation from '../shared/video/VideoNavigation';
import Lexemes from "../lexeme/Lexemes";
import Breadcrumbs from '../shared/breadcrumbs/Breadcrumbs';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function Lecture(props) {

  const { categoryName, topicName, lectureName } = props.match.params;

  const [lectureData, setLectureData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const apiUri = process.env.REACT_APP_API_PATH;
  const languageContext = useContext(LanguageContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const isMobileContext = useContext(IsMobileContext);


  /***********************
   * LIFECYCLE METHODS
  ***********************/
  useEffect(() => {
    window.scrollTo(0,0);
    
    setIsLoaded(false);
    getLecture()
      .then(lecutreResponse => {
        if (lecutreResponse) {
          setLectureData(lecutreResponse);
          setIsLoaded(true);
        }
      });
    // eslint-disable-next-line
  }, []);


  /***********************
   * HTTP METHODS
  ***********************/

  /**
   * @returns {Promise<LecturesData>}
   */
  function getLecture() {
    return axios({
      url: `${apiUri}/lectures/${lectureName}`,
      method: 'GET',
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(resposne => resposne.data)
      .catch(error => {
        log.error(`GET LECTURE: ${error}`);
        flashMessageContext.showMessage(`We could not find this lecture: ${lectureName}.`)
      });
  }


  /***********************
   * RENDER METHODS
   ***********************/

  function renderTerm(term) {
    if (term.sign) {
      return (
        <Col aria-label={`Link to term video for ${term.display_name}`} className={'mb-4'} key={term.name} xs={6} lg={4}>
          {languageContext.showEnglish &&
            term.english_tags
          }
          <VideoNavigation
            {...props}
            type='box'
            videoPath={term.sign.mp4_uris.low}
            videoThumbnail={term.sign.thumbnail_uris.low}
            link={`/terms/${term.name}`}
          />
          <Lexemes lexemesData={term.lexemes} />
        </Col>
      )
    } else {
      console.log(`No sign video for ${term.name}`)
    }
  }

  function renderRelatedTermsTitle(termsCount) {
    if (languageContext.showEnglish) {
      return (
        <h3>{termsCount} Related Terms</h3>
      )
    } else {
      return (
        <h3 className='mb-3 text-muted'>
          <FontAwesomeIcon icon={faSignLanguage} size='lg' className='d-md-none' title='Related Terms'/>
          <FontAwesomeIcon icon={faSignLanguage} size='2x' className='d-none align-middle d-md-inline' title='Related Terms'/>
          ({termsCount})
        </h3>
      )
    }
  }

  function renderTerms(terms) {

    return (
      <Col className={'mt-4'} xs={12} role="section">
        <hr />
        {renderRelatedTermsTitle(terms.length)}
        <Row>
          {terms.map(term => {
            return (
              renderTerm(term)
            )
          })}
        </Row>
      </Col>
    )
  }

  function renderLecture() {
    if (lectureData.video) {
      return (
        <Row aria-label={`Lecture video for ${lectureData.display_name}`} className="wrapper-lecture text-center mb-4">
          <Col xs={12}>
            <Video
              videoPath={isMobileContext.isMobile ? lectureData.video.hls_uris.low : lectureData.video.hls_uris.high}
              videoThumbnail={lectureData.thumbnail}
              showControls={true}
            />
          </Col>
          {renderTerms(lectureData.terms)}
        </Row>
      )
    }
  }

  // Main render
  if (!isLoaded) {
    return <div className="justify-content-md-center my-3 text-center">Loading...</div>
  }
  else if(!lectureData) {
    return <div className="justify-content-md-center my-3 text-center">We cannot find this lecture: {lectureName}. </div>
  }
  return (
    <Fragment>
      <Helmet>
        <title>ASL Clear | Lecture: {lectureData && lectureData.display_name}</title>
      </Helmet>
      <Row className='mb-4'>
        <Col xs={12}>
          <Breadcrumbs level='lecture' category={categoryName} topic={topicName} lecture={lectureName}/>
        </Col>
      </Row>
      {renderLecture()}
    </Fragment>
  )
}

/**********************
 * JSDOC DEFINITIONS
 **********************/

/**
 * @typedef LectureData
 * @property {number} id
 * @property {string} display_name
 * @property {MediaPaths} video
 * @property {MediaPaths} preview
 * @property {string} thumbnail
 * @property {boolean} has_searchable_terms
 * @property {string} lecture_name
 * @property {string} topic
 * @property {TermsData} terms
 */

/**
 * @typedef MediaPaths
 * @property {string} hls_uris.low
 * @property {string} hls_uris.med
 * @property {string} hls_uris.high
 * @property {string} mp4_uris.low
 * @property {string} mp4_uris.med
 * @property {string} mp4_uris.high
 * @property {string} thumbnail_uris.low
 * @property {string} thumbnail_uris.med
 * @property {string} thumbnail_uris.high
 */

export default Lecture;

