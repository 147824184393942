import React, {useState, useContext} from 'react';
import Video from "./Video";

import LazyLoad from 'react-lazy-load';

import ReactGA from 'react-ga';
import IsMobileContext from '../../../context/isMobile-context';

/**
 * Video Navigation Component
 *
 * @param {{
 *   match: {
 *     params: {
 *     }
 *   },
 *   videoPath: string
 *   videoThumbnail: string
 *   imageIcon: string
 *   link: string
 *   type: string | icon or box
 *   history
 *   navigateInPlace: boolean - Don't update the history
 * }}props
 *
 * @returns {*}
 * @constructor
 */
const VideoNavigation = (props) => {

  /***********************
   * INITIALIZATION
   ***********************/

  const [showVideo, setShowVideo] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [activeMobileTarget, setActiveMobileTarget] = useState(null);
  // const [activeMobileTarget, setActiveMobileTarget] = useState(null);

  const isMobileContext = useContext(IsMobileContext);
  const isMobile = isMobileContext.isMobile;


  /**************************
   * STYLES
   **************************/

  const iconHeight = props.size || 150;

  const linkStyle = {'cursor' : props.link ? 'pointer' : 'auto'};

  const iconStyle = {'height': `${iconHeight}px`, 'width': `${iconHeight}px`};

  const boxStyle = {'width': '100%'};


  /*************************
   * EVENT HANDLERS
   *************************/

  /**
   * This function handles hoverOver on desktop, and the first click on mobile.
   */
  function handleFocus() {
    if (!isMobile) {
      setShowVideo(true);
      setPlayVideo(true);
    }
  }

  function handleUnfocus() {
    setShowVideo(false);
    setPlayVideo(false);
    setActiveMobileTarget(null);
  }

  function handleClick(e, id) {
    e.stopPropagation();

    // Default action: If desktop, or second click on mobile
    if (!isMobile || (isMobile && activeMobileTarget === id)) {
      return props.navigateInPlace ? props.history.replace(props.link) : props.history.push(props.link);
    } 
    // If first click on mobile, prepare it for the next click, and play the video
    else {
      setActiveMobileTarget(id);
      setShowVideo(true);
      setPlayVideo(true);
    }
  }

  function handleVideoFinishedPlaying() {
    ReactGA.event({
      category: 'Video',
      action: `Finished playing: ${props.videoPath}`,
    });
    setShowVideo(false);
    setPlayVideo(false);
    setTimeout(() => {
      setActiveMobileTarget(null);
    }, 2000)
  }


  /*************************
   * RENDER METHODS
   *************************/

  function renderVideo() {
    return (
      <div style={linkStyle} hidden={props.type === 'icon' && !showVideo}>
          <Video 
            videoPath = {props.videoPath}
            videoThumbnail = {props.videoThumbnail}
            shouldPlay={playVideo}
            handleFinishedPlaying={handleVideoFinishedPlaying}
            handleVideoLoaded={props.handleVideoLoaded}
            type={props.type}
            showControls={false}
          />
      </div>
    )
  }

  function renderIcon() {
    return (
      <img className={'img-fluid'}
           hidden={showVideo}
           alt={'topic icon'}
           src= {props.imageIcon}
           title={'topic icon'}
           />
    )
  }

  function renderVideoNavigation() {
    if (props.type === 'icon') {
      return (
        <LazyLoad height={iconHeight} offset={400}>
          <div style={iconStyle}
              role="link"
              tabIndex="0"
              className={'border d-inline-block rounded-circle'}
              onMouseEnter={() => handleFocus(props.videoPath)}
              onMouseLeave={handleUnfocus}
              onMouseUp={(event) => handleClick(event, props.videoPath)}
          >
            {renderIcon()}
            {renderVideo()}
          </div>
        </LazyLoad>
      )
    } else if (props.type === 'box') {
      return (
        <LazyLoad offsetBottom={400}>
          <div style={boxStyle}
              onMouseEnter={handleFocus}
              onMouseLeave={handleUnfocus}
              onMouseUp={(event) => handleClick(event, props.videoPath)}
          >
              {renderVideo()}
          </div>
        </LazyLoad>
      )
    }
  }

  return (
    renderVideoNavigation()
  )
};

export default VideoNavigation;