import React from 'react';

export const IsMobileContext = React.createContext({ 
  isMobile : null
});

export const IsMobileContextProvider = props => {

  function getIsIOS() {
    const isMostIOS = /ip(hone|od)/i.test(navigator.userAgent);
  
    // https://stackoverflow.com/a/58017456/1832474
    const isNewIPad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

    return isMostIOS || isNewIPad;
  }

  function getIsMobile() {
    const isMostMobile = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
      .test(navigator.userAgent);
  
    return isMostMobile || getIsIOS();
  }


  return ( 
    <IsMobileContext.Provider value={{
      isIOS: getIsIOS(),
      isMobile: getIsMobile()
    }}>
      {props.children}
    </IsMobileContext.Provider>
  )
};

export default IsMobileContext;
