import React, {useContext} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';

import { Breadcrumb } from 'react-bootstrap';

import {toTitle} from '../../../utils/stringUtils';

import LanguageContext from '../../../context/language-context';

import './Breadcrumbs.css';


/**
 * 
 * @param {*} props 
 */
function Breadcrumbs(props) {


  /***********************
   * INITIALIZATION
   ***********************/

  // This matches up with the size of the FontAwesome 2x icon size.
  const breadcrumbSize = 36;

  const languageContext = useContext(LanguageContext);

  /***********************
   * RENDER METHODS
   ***********************/

  function renderHomeButton() {
    return (
      <Breadcrumb.Item title={'All Categories'} href="/">
        <FontAwesomeIcon size='2x' style={{verticalAlign: 'middle'}} icon={faHome}/>
        {languageContext.showEnglish && <figcaption className='d-none d-md-none d-md-inline mx-1'>All Categories</figcaption>}
      </Breadcrumb.Item>
    )
  }

  function renderCategory(category) {
    return (
      <Breadcrumb.Item 
        title={`Category: ${category}`} 
        href={`/categories/${category}`}
        active={props.level === 'category'}
      >
        <img
          style={{verticalAlign: 'middle'}}
          width={breadcrumbSize}
          height={breadcrumbSize}
          title={`Category: ${category}`}
          src={`/images/topic-and-category-icons/${category}.png`}
          alt={`Category: ${category}`}
        />
        {languageContext.showEnglish && <figcaption className='d-none d-md-inline mx-1'>{toTitle(category)}</figcaption>}
      </Breadcrumb.Item>
    )
  }

  function renderTopic(category, topic) {
    return (
      <Breadcrumb.Item 
        title={`Topic: ${topic}`} 
        href={`/categories/${category}/topics/${topic}`}
        active={props.level === 'topic'}
      >
        <img
          style={{verticalAlign: 'middle'}}
          width={breadcrumbSize}
          height={breadcrumbSize}
          title={`Topic: ${topic}`}
          src={`/images/topic-and-category-icons/${topic}.png`}
          alt={`Topic: ${topic}`}
        />
        {languageContext.showEnglish && <figcaption className='d-none d-md-inline mx-1'>{toTitle(topic)}</figcaption>}
      </Breadcrumb.Item>
    )
  }

  function renderLecture(category, topic, lecture) {
    return (
      <Breadcrumb.Item 
        title={`Lecture: ${lecture}`} 
        href={`/categories/${category}/topics/${topic}/lectures/${lecture}`}
        active={props.level === 'lecture'}
      >
        <FontAwesomeIcon size='2x' style={{verticalAlign: 'middle'}} icon={faChalkboardTeacher}/>
        {languageContext.showEnglish && <figcaption className='d-none d-md-inline mx-1'>{toTitle(lecture)}</figcaption>}
      </Breadcrumb.Item>
    )
  }

  return (
    <Breadcrumb className='text-center' style={{textAlign: 'center'}}>
      {renderHomeButton()}
      {props.category && renderCategory(props.category)}
      {props.topic && props.topic !== props.category ? renderTopic(props.category, props.topic) : null}
      {props.lecture && renderLecture(props.category, props.topic, props.lecture)}
    </Breadcrumb>
  )
}

export default Breadcrumbs