import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';

import { Helmet } from "react-helmet";

// Bootstrap Components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import log from '../../utils/logger';
import {toTitle} from '../../utils/stringUtils';

import { FlashMessageContext } from '../../context/flash-message';
import LanguageContext from '../../context/language-context';
import { IsMobileContext } from '../../context/isMobile-context';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faSignLanguage } from '@fortawesome/free-solid-svg-icons';

import BackButton from '../shared/BackButton';
import Video from '../shared/video/Video';
import VideoNavigation from '../shared/video/VideoNavigation';
import Lexemes from "../lexeme/Lexemes";

function Term(props) {

  /***********************
   * INITIALIZATION
  ***********************/
  const { termName } = props.match.params;
  const [termData, setTermData] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const apiUri = process.env.REACT_APP_API_PATH;

  const languageContext = useContext(LanguageContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const isMobileContext = useContext(IsMobileContext);
  const isMobile = isMobileContext.isMobile;

  /***********************
   * LIFECYCLE METHODS
  ***********************/
  useEffect(() => {
    window.scrollTo(0,0);
    
    setIsLoaded(false);

    getTerm()
      .then(termsResponse => {
        if (termsResponse) {
          setTermData(termsResponse);
          setIsLoaded(true);
        }
      })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.location.hash) {
      setActiveSection(props.location.hash.split('#')[1])
    } else {
      setActiveSection('term')
    }
    // eslint-disable-next-line
  }, [props.location.hash]);


  /***********************
   * HTTP METHODS
  ***********************/

  /**
   * @returns {Promise<TermsData>}
   */
  function getTerm() {
    return axios({
      url: `${apiUri}/terms/${termName}`,
      method: 'GET',
      headers: { 'Access-Control-Allow-Origin': '*' }
    })
      .then(resposne => {
        return resposne.data
      })
      .catch(error => {
        log.error(`GET TERM: ${error}`);
        flashMessageContext.showMessage('We cannot find terms')
      });
  }


  /***********************
   * RENDER METHODS
   ***********************/

  /**
   * Render the top three boxes for term, Definition and Example selection options
   * @returns {*}
   */
  function renderSelections() {
    /**
     * Render the 1st selection box: term
     * @returns {*}
     */
    function renderTermSelection() {
      return (
        <Col xs={4} 
             md={12} 
             className={activeSection === 'term' || !activeSection ? 'alert alert-info' : 'alert'}
             aria-label={`Sign for term: ${props.display_name}`}
        >
          <VideoNavigation
            {...props}
            videoPath='/videos/term/term.mp4'
            videoThumbnail='/videos/term/term.jpg'
            type='box'
            link='#term'
            navigateInPlace={true}
          />
          {languageContext.showEnglish &&
            <p>Term</p>
          }
        </Col>
      )
    }

    /**
     * Render the 2nd selection box: Definition
     * @returns {*}
     */
    function renderDefinitionSelection() {
      if (termData.definition) {
        return (
          <Col xs={4} 
               md={12} 
               className={activeSection === 'definition' || !activeSection ? 'alert alert-info' : 'alert'}
               aria-label={`Definition of term: ${props.display_name}`}
          >
            <VideoNavigation
              {...props}
              videoPath='/videos/term/definition.mp4'
              videoThumbnail='/videos/term/definition.jpg'
              type='box'
              link='#definition'
              navigateInPlace={true}
            />
            {languageContext.showEnglish &&
              <p>Definition</p>
            }
          </Col>
        )
      }
    }

    /**
     * Render the 3rd selection box: Example
     * @returns {*}
     */
    function renderExampleSelection() {
      if (termData.example) {
        return (
          <Col xs={4} 
               md={12} 
               className={activeSection === 'example' || !activeSection ? 'alert alert-info' : 'alert'}
               aria-label={`Example of term: ${props.display_name}`}
          >
            <VideoNavigation
              {...props}
              videoPath='/videos/term/example.mp4'
              videoThumbnail='/videos/term/example.jpg'
              type='box'
              link='#example'
              navigateInPlace={true}
            />
            {languageContext.showEnglish &&
              <p>Example</p>
            }
          </Col>
        )
      }
    }

    // Render the three together
    return (
      <Row className={'justify-content-center'}>
        {renderTermSelection()}
        {renderDefinitionSelection()}
        {renderExampleSelection()}
      </Row>
    )
  }

  function  renderTermTitle() {
    if (languageContext.showEnglish) {
      return (
        <div className='english'>
          <h2>{toTitle(activeSection)} | {termData.display_name} </h2>
        </div>
      )
    } else {
      return (
        // Kick it over to the left a bit to balance out the back button
        <div className='text-muted' style={{marginLeft: '-24px'}}>
          <FontAwesomeIcon icon={faSignLanguage} size='lg' className='d-md-none' title='Term'/>
          <FontAwesomeIcon icon={faSignLanguage} size='3x' className='d-none d-md-flex' title='Term'/>
        </div>
      )
    }
  }

  /**
   * This is the main video selected from the boxes above
   * @returns {*}
   */
  function renderMainDisplay() {

    const sign = termData.sign

    if (sign) {
      const thumbnail = isMobile ? sign.thumbnail_uris.low : sign.thumbnail_uris.high;
      let videoPathSubsection = isMobile ? sign.mp4_uris.low : sign.mp4_uris.high;

      if (activeSection === 'definition') {
        videoPathSubsection = isMobile ? termData.definition.mp4_uris.low : termData.definition.mp4_uris.high;
      } else if (activeSection === 'example') {
        videoPathSubsection = isMobile ? termData.example.mp4_uris.low : termData.example.mp4_uris.high;
      }
  
      return (
        <div className={'mb-4'}>
          <Video
            videoPath={videoPathSubsection}
            videoThumbnail={thumbnail}
            showControls={true}
            shouldPlay={true}
          />
  
          <Row className={'justify-content-center'}>
            <Col sm={8}>
              <Lexemes lexemesData={termData.lexemes} />
            </Col>
          </Row>
        </div>
      );
    }
  }

  function renderRelatedLecturesTitle() {
    if (languageContext.showEnglish) {
      return (
        <h3>Related Lectures</h3>
      )
    } else {
      return (
        <h3 className='mb-3 text-muted'>
          <FontAwesomeIcon icon={faChalkboardTeacher} size='lg' className='d-md-none' title='Related Lectures'/>
          <FontAwesomeIcon icon={faChalkboardTeacher} size='3x' className='d-none d-md-inline' title='Related Lectures'/>
        </h3>
      )
    }
  }

  /**
   * Render the related lectures
   * @returns {*}
   */
  function renderRelatedLectures() {
    return (
      <Col xs={12} className={'text-center'}>
        <hr />
        {renderRelatedLecturesTitle()}
        <Row className='my-3 justify-content-center'>
          {termData.lectures.map(lecture => {
            // TODO
            if (lecture.preview) {
              return (
                <Col key={lecture.name} className="mb-3" xs={6} lg={4}>
                  {languageContext.showEnglish &&
                    lecture.display_name
                  }
                  <VideoNavigation
                    {...props}
                    type='box'
                    videoPath={lecture.preview.mp4_uris.low}
                    videoThumbnail={lecture.thumbnail}
                    link={`/categories/${lecture.category_name}/topics/${lecture.topic_name}/lectures/${lecture.name}`}
                  />
                </Col>
              )
            } else {
              return null;
            }
          })}
        </Row>
      </Col>
    )
  }

  // Main render
  if (!isLoaded) {
    return <div className="my-3 text-center">Loading...</div>
  }
  else if(!termData) {
    return <div className="my-3 text-center">Sorry, we cannot find this term: {termName} </div>
  }
  return (
    <Fragment>
      <Helmet>
        <title>ASL Clear | Term: {termData.display_name}</title>
      </Helmet>
      <div className='d-flex justify-content-between mb-4'>
        <div>
        <BackButton/>
        </div>
        <div className='d-center text-center'>
          <h2>
            {renderTermTitle()}
          </h2>
        </div>
        <div/>
      </div>
      {/*Flip the columns around, so the right side is on top on mobile*/}
      <Row className="my-3 justify-content-center text-center">
        <Col xs={{ span: 12, order: 2 }} md={{ span: 9, order: 1 }}>
          {renderMainDisplay()}
        </Col>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 3, order: 2 }}>
          {renderSelections()}
        </Col>
      </Row>
      <Row className='mb-4'>
        {renderRelatedLectures()}
      </Row>
    </Fragment>
  )
}

/**********************
 * JSDOC DEFINITIONS
 **********************/

/**
 * @typedef Definition
 * @property {number} id
 * @property {boolean} is_final
 * @property {number} term_id
 * @property {Word[]} words
 */

/**
 * @typedef TermData
 * @property {number} term_id
 * @property {string} term_name
 * @property {LecturesData[]} lectures
 * @property {MediaPaths} definition
 * @property {MediaPaths} sign
 * @property {MediaPaths} example
 * @property {string} display_name
 * @property {boolean} has_definitions
 * @property {boolean} is_finalized
 * @property {*} definition
 */

/**
 * @typedef TermsData
 * @property {TermData[]} definitions_by_term
 */

export default Term;

