import React, { useContext } from 'react';

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { LanguageContext } from '../../context/language-context';

import VideoNavigation from '../shared/video/VideoNavigation';

function Categories(props) {
  const languageContext = useContext(LanguageContext);
  
  return (
    <section>
      <Row className="circleVideo text-center mt-5 pt-5">
        <Col xs={6} lg={3} aria-label='Link to Category: Science'>
          <VideoNavigation {...props}
                          link={'/categories/science'}
                          type={'icon'}
                          videoPath={"/videos/topic-and-category-icons/science.mp4"}
                          imageIcon={"/images/topic-and-category-icons/science.png"}
          />
          {languageContext.showEnglish && 
            <p>Science</p>
          }
        </Col>
        <Col xs={6} lg={3} aria-label='Link to Category: Technology'>
          <VideoNavigation {...props}
                          link={'/categories/technology/topics/technology'}
                          type={'icon'}
                          videoPath={"/videos/topic-and-category-icons/technology.mp4"}
                          imageIcon={"/images/topic-and-category-icons/technology.png"}
          />
          {languageContext.showEnglish && 
            <p>Technology</p>
          }
        </Col>
        <Col xs={6} lg={3} aria-label='Link to Category: Engineering'>
          <VideoNavigation {...props}
                          link={'/categories/engineering/topics/engineering'}
                          type={'icon'}
                          videoPath={"/videos/topic-and-category-icons/engineering.mp4"}
                          imageIcon={"/images/topic-and-category-icons/engineering.png"}
          />
          {languageContext.showEnglish && 
            <p>Engineering</p>
          }
        </Col>
        <Col xs={6} lg={3} aria-label='Link to Category: Math'>
          <VideoNavigation {...props}
                          link={'/categories/math'}
                          type={'icon'}
                          videoPath={"/videos/topic-and-category-icons/math.mp4"}
                          imageIcon={"/images/topic-and-category-icons/math.png"}
          />
          {languageContext.showEnglish && 
            <p>Math</p>
          }
        </Col>
      </Row>
    </section>
  );
}

export default Categories;