import React, { useState } from 'react';

export const LanguageContext = React.createContext({ 
  showEnglish : false,
  setShowEnglish: () => {}
});

export const LanguageProvider = props => {
  const [showEnglish, setShowEnglish] = useState(getEnglishLocalStorage());

  function getEnglishLocalStorage() {
    return localStorage.getItem('show_english') === 'true';
  }
  
  const handleShowEnglish = (shouldShowEnglish) => {
    localStorage.setItem('show_english', shouldShowEnglish);
    setShowEnglish(shouldShowEnglish);
  };

  return ( 
    <LanguageContext.Provider value={{
      showEnglish: showEnglish,
      setShowEnglish: handleShowEnglish
    }}>
      {props.children}
    </LanguageContext.Provider>
  )
};

export default LanguageContext;
