import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

const customJSON = log => ({
  message: log.message,
  level: log.level.label,
  stacktrace: log.stacktrace
});

if (process.env.REACT_APP_SEND_LOGS === 'true') {
  const apiPath = `${process.env.REACT_APP_API_PATH}/log`;
  remote.apply(log, {
    format: customJSON,
    url: apiPath,
    stacktrace: {
      depth: 10
    }
  });
}

export default log;