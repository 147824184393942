import React, {useState, useContext} from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from 'react-bootstrap/Nav';

import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import LanguageContext from '../../context/language-context';
import EnglishSearch from './EnglishSearch/EnglishSearch';
import GraphemeSearch from './GraphemeSearch/GraphemeSearch';

function NavBar() {

  /*****************
   * INITIALIZATION
   *****************/
  const languageContext = useContext(LanguageContext);

  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [englishToggle, setEnglishToggle] = useState(languageContext.showEnglish);



  /*****************
   * STYLES
   *****************/
  const helpButtonStyle = { cursor: "pointer" };

  /*****************
   * HANDLER METHODS
   *****************/

  function handleToggleEnglish() {
    setEnglishToggle(!englishToggle);
    languageContext.setShowEnglish(!englishToggle);
  }

  function handleToggleMobileSearch() {
    setShowMobileSearch(!showMobileSearch);
  }


  /*****************
   * RENDER METHODS
   *****************/

  function renderSearchBar() {
    if (languageContext.showEnglish) {
      return (
        <EnglishSearch/>
      )
    } else {
      return (
        <GraphemeSearch/>
      )
    }
  }

  function renderMobileOrDesktopSearch() {
    if (showMobileSearch) {
      return (
        /* Show the mobile bar underneath the main bar (if on mobile) */
        <Navbar bg="light" hidden={!showMobileSearch}>
          {renderSearchBar()}
        </Navbar>
      )
    } else {
      return (
        <Navbar bg="light">
          <Row className='m-0 no-gutters' style={{width: '100%'}}>
            
            <Col>
              <Navbar.Brand href='/' aria-label='Link to home'>
                <img src={'/logo.png'} alt='ASL Clear Logo' width="75em" title='ASL Clear Logo' /> 
              </Navbar.Brand>
            </Col>

            <Col md={8} className='d-none d-md-flex'>
              <Nav className={'mx-auto'}>
                {renderSearchBar()}
              </Nav>
            </Col>
            
            {/* Display the mobile search bar if on mobile */}
            <Col className="d-flex justify-content-right">
              <Nav className={'ml-auto'} style={helpButtonStyle}>
                <Nav.Link className='d-md-none' aria-label='Show search bar' onClick={handleToggleMobileSearch}>
                  <FontAwesomeIcon icon={faSearch} size='2x'/>
                </Nav.Link>
                <div className='mx-2 mt-2'>
                  <div>
                    <label className="switch" aria-label="Toggle English labels"> 
                      <input type="checkbox" 
                             checked={languageContext.showEnglish} 
                             aria-labelledby='englishToggleLabel' 
                             onChange={handleToggleEnglish} 
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className='text-center' style={{marginTop: '-.5rem'}}>
                    <span id='englishToggleLabel' className={languageContext.showEnglish ? '' : 'text-muted text-small'}>English</span>
                  </div>
                </div>
                <Nav.Link href='/faq' aria-label="Link to FAQ" className={window.location.pathname === '/faq' && 'text-dark'}>
                  <FontAwesomeIcon icon={faQuestionCircle} size='2x' className='d-md-none'/>
                  <FontAwesomeIcon icon={faQuestionCircle} size='3x' className='d-none d-md-flex'/>
                </Nav.Link>
              </Nav>
            </Col>

          </Row>
        </Navbar>
      )
    }
  }

  return( 
    <header className="mb-3">
        {renderMobileOrDesktopSearch()}
    </header>
  );
}

export default NavBar;