export function camelToSnake(string) {
    return string.replace(/[\w]([A-Z])/g, function(m) {
        return m[0] + "_" + m[1];
    }).toLowerCase();
}

export function toTitle(string) {

    return string.replace(/_/g, ' ').replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function camelToTitle(string) {
    let newString = camelToSnake(string)
    return toTitle(newString)
}