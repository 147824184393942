import React, { useState } from 'react';

import {locations} from '../data/graphemes.json'

function Location(props) {

  /***********************
   * INITIALIZATION
  ***********************/
  const [isFocused, setIsFocused] = useState(false);

  const location = locations.find(location => location.id === props.id);

  /***********************
   * HANDLER METHODS
  ***********************/

  function handleFocus() {
    setIsFocused(true)
  }

  function handleUnfocus() {
    setIsFocused(false);
  }


  /***********************
   * RENDER METHODS
  ***********************/

  function renderGrapheme() {
    return (
      <img src={location.imagePath} alt={location.name} className='img-fluid border'/>
    )
  }

  function renderExplanation() {
    return (
      <img src={location.bodyPath} alt={location.name} className='img-fluid border'/>
    )
  }

  return (
    <div onMouseEnter={handleFocus} onMouseLeave={handleUnfocus}>
      {isFocused ? renderExplanation() : renderGrapheme() }
    </div>
  )
}

export default Location;