import React from 'react';
import {camelToTitle} from '../../../utils/stringUtils';

/**
 * This component handles the Grapheme dropdowns (Movements, Location, Handshapes).
 * It would be great to be able to abstract out the rendering of the elements and use /lexeme/graphemes, but
 * it's hard with the functionality needed, especially the requirements for mobile.
 * @param {*} props 
 */
function SecondaryMenu(props) {

  /*********************
   * STYLES
   *********************/

  const submenuStyle = {
    display: 'flex',
    backgroundColor: '#f9f9f9',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)'
  }

  const dropdownElementStyle = {
     backgroundColor: '#f9f9f9',
     boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
     height: props.searchElementSize,
     width: props.searchElementSize
 }

 const graphemeStyle = {
   width: props.searchElementSize, 
   height: props.searchElementSize, 
   textAlign: 'center'
  }


  /*********************
   * RENDER METHODS
   *********************/

  /**
   * Render an image element of the dropdown (the front of all elements, and the backs of Location and Handshape)
   *
   * @param id: string|number
   * @param name: string
   * @param imagePath: string
   * @returns {*}
   */
  function renderImageDropdownElement(id, name, imagePath) {
    return (
      <img key={name}
           aria-label={`Grapheme search element: ${camelToTitle(props.searchCategory)}: ${camelToTitle(name)}`}
           name={props.searchCategory}
           src={imagePath}
           id={`${props.searchCategory}--${id}`}
           style={dropdownElementStyle}
           alt={name}
           onMouseEnter={() => {props.handleDropdownItemFocus(id)}}
           onMouseLeave={() => {props.handleSubmenuDropdownItemUnfocus()}}
      />
    )
  }

  /**
   * Render a video element of the dropdown (currently, just the flip side of Movements)
   *
   * @param element {{
   *   id: number
   *   name: string
   *   videoPath: string
   * }}
   * @returns {*}
   */
  function renderVideoDropdown(element) {
    return (
      <div key={element.name}
           aria-label={`Grapheme search element: ${camelToTitle(props.searchCategory)}: ${camelToTitle(element.name)}`}
           style={dropdownElementStyle}
           className={'embed-responsive embed-responsive-1by1'}
           onMouseLeave={props.handleDropdownItemUnfocus}
      >
        <video key={element.name}
               name={props.searchCategory}
               id={`${props.searchCategory}--${element.id}`}
               className="embed-responsive-item"
               autoPlay={true}>
          <source src={element.videoPath}/>
        </video>
      </div>
    )
  }

  /**
   * Render a menu element that itself is a menu (Handshapes)
   *
   * @param id: number
   * @param name: string
   * @param handshapePath: string
   * @param subElements {{id: number, name: string, imagePath: string}[]}
   * @returns {*}
   */
  function renderHandShapeGroup(id, name, handshapePath, subElements) {
    /**
     * Render the handshape group elements
     * @type {*[]}
     */
    const handshapeGroupElements = subElements.map((subelement, index) => {
      const handshapePaths = subelement.subHandshapes.map(subHandshape => {return subHandshape.imagePath});
      return (
        renderGraphemeWithHandshapes(`${id}.${subelement.id}`, subelement.name, subelement.imagePath, handshapePaths, index)
      )
    });

    /**
     * These are similar to the image element, although they show a single grapheme with one or more
     * sub-handshapes that are associated with it
     *
     * @param id
     * @param name
     * @param imagePath
     * @param handshapePaths
     * @returns {*}
     */
    function renderGraphemeWithHandshapes(id, name, imagePath, handshapePaths, graphemeIndex) {
      const isFocused = id.split('.')[1] === props.focusedSubmenuElementId;

      /**
       * Render the smaller handshape image(s) that are part of a grapheme
       */
      const handshapeImages = handshapePaths.map(handshapePath => {
        return (
          <img key={handshapePath}
               src={handshapePath}
               alt={`Subhandshape: ${camelToTitle(name)}`}
               style={{opacity: isFocused ? 1 : .75}}
               width={isFocused ? props.searchElementSize : props.searchElementSize / 2.1}
          />
        );
      });

      /**
       * Render the grapheme with the handshapes below
       */
      return (
        <div key={name} style={graphemeStyle}>
          <img name={props.searchCategory}
              src={imagePath}
              className={isFocused ? 'border border-info' : null}
              id={`${props.searchCategory}--${id}`}
              alt={camelToTitle(name)}
              height={props.searchElementSize}
              width={props.searchElementSize}
              onMouseEnter={() => {props.handleSubmenuDropdownItemFocus(id)}}
              onMouseLeave={() => {props.handleSubmenuDropdownItemUnfocus()}}
          />
          <div style={{
            width: isFocused ? handshapeImages.length * props.searchElementSize : props.searchElementSize,
            left: isFocused ? (props.searchElementSize / 2) - (handshapeImages.length * props.searchElementSize / 2) : 0,
            height: isFocused ? props.searchElementSize : props.searchElementSize * .9,
            zIndex: isFocused ? 200 : 100 - graphemeIndex,
            position: 'relative',
            boxShadow: isFocused ? '0px 8px 16px 0px rgba(0,0,0,0.2)' : null
          }}>
            {handshapeImages}
          </div>
        </div>
      )
    }

    /**
     * Render the submenu
     */
    return (
      <div key={name} style={submenuStyle}>
        {renderImageDropdownElement(id, name, handshapePath)}
        {handshapeGroupElements}
      </div>
    )
  }


  // Main Render
  return props.searchElementsData.map((element) => {
    const isFocusedOnElement = parseInt(props.focusedDropdownMenuElementId) === element.id;

    let dropdownElement;
    if (props.searchCategory === 'primaryHandshape' || props.searchCategory === 'secondaryHandshape') {
      dropdownElement = isFocusedOnElement ?
        renderHandShapeGroup(element.id, element.name, element.imagePath, element.graphemes) :
        renderImageDropdownElement(element.id, element.name, element.imagePath);
        
    } else if (props.searchCategory === 'location') {
      dropdownElement = isFocusedOnElement ?
        renderImageDropdownElement(element.id, element.name, element.bodyPath) :
        renderImageDropdownElement(element.id, element.name, element.imagePath);
    } else if (props.searchCategory === 'primaryMovement' || props.searchCategory === 'secondaryMovement') {
      dropdownElement = isFocusedOnElement ?
        renderVideoDropdown(element) :
        renderImageDropdownElement(element.id, element.name, element.imagePath);
    }

    return (
      <div key={element.name} className={isFocusedOnElement ? 'border border-default' : null}>
        {dropdownElement}
      </div>
    )
  });
}

export default SecondaryMenu;