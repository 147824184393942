import React, { useState, useEffect } from "react";

import SecondaryMenu from "./SecondaryMenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { handshapes, locations, movements } from '../../lexeme/data/graphemes.json';
import { Col, Row } from "react-bootstrap";
import { camelToTitle } from "../../../utils/stringUtils";


function PrimaryMenu(props) {

  /*****************
   * INITIALIZATION
   *****************/
  const [searchElementSize, setSearchElementSize] = useState(80);


  /*****************
   * STYLES
   *****************/

  const dropdownStyle = {
    background: "#fff",
    height: searchElementSize + 10,
    alignItems: "center",
    cursor: "pointer",
  };

  const dropdownContentStyle = {
    display: "block",
    position: "absolute",
    zIndex: 100,
  };

  const clearSearchElementStyle = {
    fontWeight: "bold",
    position: "absolute",
    top: "-10px",
    right: "10px",
    color: "red",
  };

  const searchElementStyle = {
    width: searchElementSize,
    padding: "2.5px",
  };


  /*****************
   * LIFECYCLE METHODS
   *****************/

  useEffect(() => {
    setSearchElementSize(window.innerWidth >= 768 ? 80 : 55);
    // eslint-disable-next-line
  }, [window.innerWidth]);

  
  /*****************
   * RENDER METHODS
   *****************/

  /**
   * If the primary element (Movement or Handshape) isn't selected, don't let the user select a secondary one
   *
   * @param searchCategory
   * @param iconImagePath
   * @returns {*}
   */
  function renderDisabledGraphemeSearch(searchCategory, iconImagePath) {
    return (
      <Col xs={2}>
        <div style={searchElementStyle}>
          <img
            name={"topLevelMenuElement"}
            className={"img-fluid"}
            style={{ opacity: 0.25 }}
            alt={searchCategory}
            src={iconImagePath}
          />
        </div>
      </Col>
    );
  }

  /**
   * Render the Clear All button to clear all search elements
   * @returns {*}
   */
  function renderClearAllButton() {
    return (
      <Col
        xs={1}
        className="mx-auto"
        aria-label='Clear Grapheme search'
        onClick={() => props.handleClearGraphemeSearch("all")}
      >
        {props.graphemeSearch.primaryMovement ||
        props.graphemeSearch.secondaryMovement ||
        props.graphemeSearch.primaryHandshape ||
        props.graphemeSearch.secondaryHandshape ||
        props.graphemeSearch.location ? (
          <FontAwesomeIcon icon={faTimes} size="lg" color="red" />
        ) : (
          <FontAwesomeIcon icon={faTimes} size="lg" color="#ccc" />
        )}
      </Col>
    );
  }

  /**
   * Render a top level of the search bar element
   *
   * @param searchCategory string
   * @param searchCategoryStateObject
   * @param iconImagePath string
   * @param searchElementsData {locations | movements | handshapes}
   * @returns {*}
   */
  function renderGraphemeSearch(
    searchCategory,
    searchCategoryStateObject,
    iconImagePath,
    searchElementsData
  ) {
    return (
      <Col xs={2} 
           aria-label={`Grapheme search element: ${camelToTitle(searchCategory)}`}    
           tabIndex="0"
      >
        <div style={searchElementStyle}>
          <div
            style={clearSearchElementStyle}
            hidden={!searchCategoryStateObject}
            onClick={() => props.handleClearGraphemeSearch(searchCategory)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <img
            name={"topLevelMenuElement"}
            id={`topLevelMenuElement--${searchCategory}`}
            alt={camelToTitle(searchCategory)}
            src={
              searchCategoryStateObject
                ? searchCategoryStateObject.imagePath
                : iconImagePath
            }
            className={
              searchCategoryStateObject
                ? "img-fluid border border-info"
                : "img-fluid border border-default"
            }
          />
        </div>
        <div
          style={dropdownContentStyle}
          hidden={props.focusedSearchCategory !== searchCategory}
        >
          <SecondaryMenu
            searchCategory={searchCategory}
            searchElementsData={searchElementsData}
            searchElementSize={searchElementSize}
            // Dropdown events
            handleDropdownItemFocus={props.handleDropdownItemFocus}
            handleDropdownItemUnfocus={props.handleDropdownItemUnfocus}
            focusedDropdownMenuElementId={props.focusedDropdownMenuElementId}
            // Sub-handshape from main handshape
            handleSubmenuDropdownItemFocus={
              props.handleSubmenuDropdownItemFocus
            }
            handleSubmenuDropdownItemUnfocus={
              props.handleSubmenuDropdownItemUnfocus
            }
            focusedSubmenuElementId={props.focusedSubmenuElementId}
          />
        </div>
      </Col>
    );
  }

  // Main Render
  return (
    <Row
      aria-label='Search by Grapheme'
      style={dropdownStyle}
      className={"justify-content-center my-1 border"}
      ref={props.menuContainerRef}
    >
      {/* Secondary Handshape*/}
      {props.graphemeSearch.primaryHandshape
        ? renderGraphemeSearch(
            "secondaryHandshape",
            props.graphemeSearch.secondaryHandshape,
            "/images/graphemes/grapheme_search_handshape.png",
            handshapes
          )
        : renderDisabledGraphemeSearch(
            "secondaryHandshape",
            "/images/graphemes/grapheme_search_handshape.png"
          )}

      {/* Primary Handshape */}
      {renderGraphemeSearch(
        "primaryHandshape",
        props.graphemeSearch.primaryHandshape,
        "/images/graphemes/grapheme_search_handshape.png",
        handshapes
      )}

      {/* Location */}
      {renderGraphemeSearch(
        "location",
        props.graphemeSearch.location,
        "/images/graphemes/grapheme_search_location.svg",
        locations
      )}

      {/* Primary Movement */}
      {renderGraphemeSearch(
        "primaryMovement",
        props.graphemeSearch.primaryMovement,
        "/images/graphemes/grapheme_search_movement.png",
        movements
      )}

      {/* Secondary Movement */}
      {props.graphemeSearch.primaryMovement
        ? renderGraphemeSearch(
            "secondaryMovement",
            props.graphemeSearch.secondaryMovement,
            "/images/graphemes/grapheme_search_movement.png",
            movements
          )
        : renderDisabledGraphemeSearch(
            "secondaryMovement",
            "/images/graphemes/grapheme_search_movement.png"
          )}

      {/* Clear all button */}
      {renderClearAllButton()}
    </Row>
  );
}

export default PrimaryMenu;
