import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

function Footer() {

  /********************
   STYLES
   ********************/
  const footerStyle = {
    fontSize: '12px',
    fontStyle: 'italic',
    textAlign: 'left',
    backgroundColor: '#eee',
    paddingTop: '20px',
    color: 'black',
    boxShadow: '0 0 2px black',
    height: '11vh',
    minHeight: '160px'
  };

  
  return (
    <footer style={footerStyle}>
      <div className="grid-footer-logo">
        <img className="footer-logo-img" src={'/logo.png'} alt='ASL Clear Logo' width="110em" title='ASL Clear Logo'/> 
      </div>
      <div className="grid-footer-info">
        <p className='footer-text'>© 2016-2022 ASL Clear. All Rights Reserved.</p>
        <p className='footer-text'>Managed by 
          <a href="https://www.asledcenter.org/"> ASL Ed Center, LLC</a>
        </p>
        <p className='footer-text'>
          <a href='/acknowledgements'>Acknowledgements</a>
        </p>
      </div>
      <div className="grid-footer-icons">
        <p>
          <a href='https://www.facebook.com/ASLClear' aria-label='Link to our Facebook' target='_blank' rel='noopener noreferrer' alt='ASL Clear Facebook' className='mx-1'>
            <FontAwesomeIcon className="sm-icons" icon={faFacebook} size='3x'/>
          </a>
          <a href='https://twitter.com/aslclear' aria-label='Link to our Twitter' target='_blank' rel='noopener noreferrer' alt='ASL Clear Twitter' className='mx-1'>
            <FontAwesomeIcon className="sm-icons" icon={faTwitter} size='3x'/>
          </a>
          <a href='https://www.instagram.com/aslclear/' aria-label='Link to our Instagram' target='_blank' rel='noopener noreferrer' alt='ASL Clear Instagram' className='mx-1'>
            <FontAwesomeIcon className="sm-icons" icon={faInstagram} size='3x'/>
          </a>
        </p>
      </div>
    </footer>

    // <footer style={footerStyle}>
    //   <Row className='justify-content-center no-gutters'>
    //     <Col className="footer-col-logo">
    //       <img src={'/logo.png'} alt='ASL Clear Logo' width="95em" title='ASL Clear Logo' /> 
    //     </Col>
    //     <Col md={10}>
    //       <p className='footer-text'>© 2016-2021 ASL Clear. All Rights Reserved</p>
    //       <p className='footer-text'>Managed by ASL Ed Center, LLC</p>
    //       <p className='footer-text'>
    //         <a href='/image-attributions'>Acknowldgements</a>
    //       </p>
    //     </Col>
    //     <Col>
    //       <p>
    //         <a href='https://www.facebook.com/ASLClear' aria-label='Link to our Facebook' target='_blank' rel='noopener noreferrer' alt='ASL Clear Facebook' className='mx-1'>
    //           <FontAwesomeIcon icon={faFacebook} size='2x'/>
    //         </a>
    //         <a href='https://twitter.com/aslclear' aria-label='Link to our Twitter' target='_blank' rel='noopener noreferrer' alt='ASL Clear Twitter' className='mx-1'>
    //           <FontAwesomeIcon icon={faTwitter} size='2x'/>
    //         </a>
    //         <a href='https://www.instagram.com/aslclear/' aria-label='Link to our Instagram' target='_blank' rel='noopener noreferrer' alt='ASL Clear Instagram' className='mx-1'>
    //           <FontAwesomeIcon icon={faInstagram} size='2x'/>
    //         </a>
    //       </p>
    //     </Col>

    //       <p>
    //         Made possible through funding from the MA Department of Elementary and Secondary Education award 
    //         #16CT83BU16SEPPB1718. Collaborative project between Boston University's Center for the Study of Communication 
    //         and the Deaf and&nbsp;
    //         <a href='https://www.tlcdeaf.org/services/center-research-and-training' target='_blank' rel='noopener noreferrer'>
    //         The Center for Research and Training</a> at the Learning Center for the Deaf, Framingham, MA.
    //       </p>
    //       <p>
    //         © 2016-{new Date().getFullYear()} Reis, Hoffmeister, Laurence
    //       </p>
    //       <p>
    //         Application Version 2.0
    //       </p>
    //   </Row>
    // </footer>
  )
}

export default Footer;