import React, { useContext } from 'react';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { FlashMessageContext } from "../context/flash-message";

export const FlashMessage = () => {

  /*****************
   * INITIALIZATION
    *****************/

  const flashMessageContext = useContext(FlashMessageContext);

  // Default to a red alert style
  const messageClass = flashMessageContext.messageClass || 'danger';


  /*****************
   * CUSTOM STYLES
    *****************/

  const style = {
    position: 'fixed',
    top: '0px',
    left: '15px',
    width: '100%',
    zIndex: 9999,
    borderRadius: '0px'
  };


  /*****************
   * EVENT HANDLERS
    *****************/

  const handleDismissMessage = () => {
    flashMessageContext.showMessage(null);
  };


  /*****************
   * RENDER METHODS
    *****************/

  const renderMessageContent = () => {
    return (
      <Col xs={{ span: 8, offset: 2 }} className="text-center">
        {flashMessageContext.messageText}
      </Col>
    )
  };

  const renderDismissButton = () => {
    return (
      <Col className={'text-right'} xs={2}>
        <Button variant={messageClass} size={'sm'} onClick={handleDismissMessage}>
          <FontAwesomeIcon icon={faWindowClose} />
        </Button>
      </Col>
    )
  };

  if (flashMessageContext.messageText) {
    return (
      <Row style={style} className={'alert alert-' + messageClass} role="alert">
        {renderMessageContent()}
        {renderDismissButton()}
      </Row>
    );
  } else {
    return null;
  }
};

export default FlashMessage;